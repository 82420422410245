import {Status, TimeSlot} from '@wix/ambassador-table-reservations-v1-time-slot/types'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import addDays from 'date-fns/addDays'
import isSameDay from 'date-fns/isSameDay'
import isPast from 'date-fns/isPast'

import {TimeSlotsByDate} from '../../types/TimeSlots'

const TARGET_TIME_SLOTS_AMOUNT = 15
const TARGET_TIME_SLOTS_AMOUNT_NEXT_DAYS = 5

import {getScheduledTimeSlotsForPeriod} from './getScheduledTimeSlots'

const getDayTimeSlots = (
  flowAPI: ControllerFlowAPI,
  params: {
    reservationLocationId: string
    partySize: number
    date: Date
    timeSlotInterval?: number | null
    timeZone?: string | null
  },
) => getScheduledTimeSlotsForPeriod(flowAPI, {...params, period: 'day'})

const getTimeSlots = async (
  flowAPI: ControllerFlowAPI,
  params: {
    reservationLocationId: string
    partySize: number
    date: Date
    timeSlotInterval?: number | null
    statuses: Status[]
    timeZone?: string | null
  },
): Promise<TimeSlotsByDate> => {
  let timeSlots = await getScheduledTimeSlotsForPeriod(flowAPI, {...params, period: '24h'})

  if (isSomeSlotAvailable(timeSlots)) {
    timeSlots = getAppropriateTimeSlots(
      timeSlots,
      params.date,
      TARGET_TIME_SLOTS_AMOUNT,
      params.statuses,
    )

    return {
      [params.date.toDateString()]: timeSlots,
    }
  } else {
    const days = getDaysAroundDate(params.date)

    const responses = await Promise.all(
      days.map(async (date) => {
        const allSlots = await getScheduledTimeSlotsForPeriod(flowAPI, {
          ...params,
          period: '24h',
          date,
        })

        return getAppropriateTimeSlots(
          allSlots,
          date,
          TARGET_TIME_SLOTS_AMOUNT_NEXT_DAYS,
          params.statuses,
        )
      }),
    )

    const resultTimeSlots: Record<string, TimeSlot[]> = {}

    days.forEach((day, index) => {
      if (isSomeSlotAvailable(responses[index])) {
        resultTimeSlots[day.toDateString()] = responses[index]
      }
    })

    return resultTimeSlots
  }
}

// TODO fix
const getDaysAroundDate = (date: Date) => {
  const DAYS_COUNT = 5
  const MAX_DAYS_BEFORE = 2
  const days: Date[] = []

  let i = 0

  while (days.length < DAYS_COUNT) {
    const day = addDays(addDays(date, -MAX_DAYS_BEFORE), i)

    // TODO: respect schedule
    if (!isSameDay(date, day) && !isPast(day)) {
      days.push(day)
    }

    i++
  }

  return days
}

const isSomeSlotAvailable = (timeSlots: TimeSlot[]) =>
  timeSlots.some((slot) => slot.status === Status.AVAILABLE)

export const getAppropriateTimeSlots = (
  timeSlots: TimeSlot[],
  targetDate: Date,
  targetSlotsCount: number = 15,
  statuses: Status[] = Object.values(Status),
) => {
  const targetTime = targetDate.getTime()
  const sortedTimeSlots = [...timeSlots]
    .filter((slot) => statuses.includes(slot.status!))
    .sort((a, b) => a.startDate!.getTime() - b.startDate!.getTime())

  if (sortedTimeSlots.length === 0) {
    return []
  }

  let closestIndex = 0
  let minDiff = Math.abs(sortedTimeSlots[0].startDate!.getTime() - targetTime)

  for (let i = 1; i < sortedTimeSlots.length; i++) {
    const diff = Math.abs(sortedTimeSlots[i].startDate!.getTime() - targetTime)
    if (diff < minDiff) {
      closestIndex = i
      minDiff = diff
    }
  }

  let start = Math.max(0, closestIndex - Math.floor((targetSlotsCount - 1) / 2))
  const end = Math.min(sortedTimeSlots.length, start + targetSlotsCount)

  start = Math.max(0, end - targetSlotsCount)

  return sortedTimeSlots.slice(start, end)
}

export const timeSlotsService = {
  getDayTimeSlots,
  getTimeSlots,
}
