import {Status, TimeSlot} from '@wix/ambassador-table-reservations-v1-time-slot/types'
import eachMinuteOfInterval from 'date-fns/eachMinuteOfInterval'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import addMinutes from 'date-fns/addMinutes'

import {getAppropriateTimeSlots, timeSlotsService} from '../../services/timeSlotsService'
import {TimeSlotsByDate} from '../../types/TimeSlots'

import {EDITOR_MOCK_RESERVATION_LOCATION_ID} from './getReservationLocationsMock'

export const getTimeSlotsMock = async (
  flowApi: ControllerFlowAPI,
  params: {
    reservationLocationId: string
    partySize: number
    date: Date
    timeSlotInterval?: number | null
    statuses: Status[]
    timeZone?: string | null
  },
): Promise<TimeSlotsByDate> => {
  if (!params.date) {
    return {}
  }

  let timeSlotsFromApi: TimeSlotsByDate = {}

  if (params.reservationLocationId !== EDITOR_MOCK_RESERVATION_LOCATION_ID) {
    try {
      timeSlotsFromApi = await timeSlotsService.getTimeSlots(flowApi, params)
    } catch (e) {}
  }

  if (Object.keys(timeSlotsFromApi).length) {
    return timeSlotsFromApi
  } else {
    const selectedTime = params.date

    const intervals = eachMinuteOfInterval(
      {
        start: addMinutes(selectedTime, -7 * 15),
        end: addMinutes(selectedTime, 7 * 15),
      },
      {step: 15},
    )

    return {
      [selectedTime.toDateString()]: getAppropriateTimeSlots(
        generateTimeSlots(intervals.slice(0, 15)),
        params.date,
        15,
        params.statuses,
      ),
    }
  }
}

const generateTimeSlot = (startDate: Date, status: Status): TimeSlot => ({
  startDate,
  duration: 30,
  status,
  tableCombinations: [],
})

const generateTimeSlots = (dates: Date[]): TimeSlot[] =>
  Array.from({length: dates.length}).map((_, index) =>
    generateTimeSlot(dates[index], Status.AVAILABLE),
  )
