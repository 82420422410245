import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {
  GetScheduledTimeSlotsRequest,
  TimeSlot,
} from '@wix/ambassador-table-reservations-v1-time-slot/types'
import {getScheduledTimeSlots as getScheduledTimeSlotsService} from '@wix/ambassador-table-reservations-v1-time-slot/http'
import subMinutes from 'date-fns/subMinutes'
import addMinutes from 'date-fns/addMinutes'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import setSeconds from 'date-fns/setSeconds'
import setMilliseconds from 'date-fns/setMilliseconds'
import {utcToZonedTime, zonedTimeToUtc} from '@wix/table-reservations-lib/timezone'

import {addNonWorkingHoursTimeSlots} from './nonWorkingHoursTimeSlots'

// 24h - a period from "selected date - 11:59" to "selected time + 11:59"
// day - a period from 00:00 to 23:59:59:999 of selected date
type Period = 'day' | '24h'

const MINUTES_IN_HOUR = 60
const MINUTES_IN_DAY = 1440
const MINUTES_IN_HALF_DAY = MINUTES_IN_DAY / 2

export interface GetScheduledTimeSlotsForPeriodParams {
  reservationLocationId: string
  partySize: number
  date: Date
  period: Period
  timeSlotInterval?: number | null
  timeZone?: string | null
}

export const getScheduledTimeSlotsForPeriod = async (
  flowAPI: ControllerFlowAPI,
  {
    reservationLocationId,
    partySize,
    date,
    timeSlotInterval,
    period,
    timeZone,
  }: GetScheduledTimeSlotsForPeriodParams,
) => {
  const requestParams = generateGetScheduledTimeSlotsRequest({
    reservationLocationId,
    partySize,
    date,
    period,
    timeZone,
  })

  const timeSlots = await getScheduledTimeSlots(flowAPI, requestParams)

  if (!timeSlots.length) {
    return []
  }

  const normalizedTimeSlots: TimeSlot[] = normalizeTimeSlots({timeSlots, timeZone})

  if (timeSlotInterval) {
    return addNonWorkingHoursTimeSlots({
      timeSlots: normalizedTimeSlots,
      timeSlotInterval,
    })
  }

  return normalizedTimeSlots
}

const generateGetScheduledTimeSlotsRequest = ({
  reservationLocationId,
  partySize,
  date,
  period,
  timeZone,
}: {
  reservationLocationId: string
  partySize: number
  date: Date
  period: Period
  timeZone?: string | null
}): GetScheduledTimeSlotsRequest => {
  let startDate = new Date(date)
  let endDate = new Date(date)

  if (period === 'day') {
    startDate = startOfDay(startDate)
    endDate = endOfDay(endDate)
  } else {
    startDate = subMinutes(startDate, MINUTES_IN_HALF_DAY)
    endDate = addMinutes(startDate, MINUTES_IN_DAY - 1)
  }

  // Remove milliseconds and seconds
  startDate = setMilliseconds(setSeconds(startDate, 0), 0)
  endDate = setMilliseconds(setSeconds(endDate, 0), 0)

  // Detect "daylight saving time"
  if (startDate.getTimezoneOffset() !== endDate.getTimezoneOffset()) {
    endDate = subMinutes(endDate, MINUTES_IN_HOUR)
  }

  // TODO: remove one extra hour from endDate to test server errors
  endDate = subMinutes(endDate, 15)

  if (timeZone) {
    startDate = zonedTimeToUtc(startDate, timeZone)
    endDate = zonedTimeToUtc(endDate, timeZone)
  }

  return {
    reservationLocationId,
    timeRange: {startDate, endDate},
    partySize,
  }
}

const getScheduledTimeSlots = async (
  flowAPI: ControllerFlowAPI,
  params: GetScheduledTimeSlotsRequest,
): Promise<TimeSlot[]> => {
  try {
    const response = await flowAPI.httpClient.request(getScheduledTimeSlotsService(params))

    return response.data.timeSlots ?? []
  } catch (error) {
    flowAPI.panoramaClient.errorMonitor().reportError(error as Error, {
      context: {
        requestParams: params,
      },
    })

    throw error
  }
}

const normalizeTimeSlots = ({
  timeSlots,
  timeZone,
}: {
  timeSlots: TimeSlot[]
  timeZone?: string | null
}) =>
  timeSlots.map((t) => ({
    ...t,
    startDate: utcToZonedTime(t.startDate!, timeZone),
  }))
