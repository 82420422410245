import {Status, TimeSlot} from '@wix/ambassador-table-reservations-v1-time-slot/types'
import isSameDay from 'date-fns/isSameDay'
import addMilliseconds from 'date-fns/addMilliseconds'

export const addNonWorkingHoursTimeSlots = ({
  timeSlots,
  timeSlotInterval,
}: {
  timeSlots: TimeSlot[]
  timeSlotInterval: number
}): TimeSlot[] => {
  const timeSlotIntervalInMilliseconds = timeSlotInterval * 60 * 1000

  const firstTimeSlotStartDate = timeSlots[0].startDate
  const lastTimeSlotStartDate = timeSlots[timeSlots.length - 1].startDate

  const result = [
    ...(firstTimeSlotStartDate
      ? addNonWorkingHoursTimeSlotsBeforeOrAfterDate(
          firstTimeSlotStartDate,
          timeSlotIntervalInMilliseconds,
          'before',
        )
      : []),
    ...addNonWorkingHoursTimeSlotsBetweenTimeSlots(timeSlots, timeSlotIntervalInMilliseconds),
    ...(lastTimeSlotStartDate
      ? addNonWorkingHoursTimeSlotsBeforeOrAfterDate(
          lastTimeSlotStartDate,
          timeSlotIntervalInMilliseconds,
          'after',
        )
      : []),
  ]

  result.sort((a, b) => a.startDate!.getTime() - b.startDate!.getTime())

  return result
}

const addNonWorkingHoursTimeSlotsBeforeOrAfterDate = (
  date: Date,
  timeSlotIntervalInMilliseconds: number,
  type: 'before' | 'after',
) => {
  const result: TimeSlot[] = []

  const dateTime = new Date(date).getTime()

  let iteratorDateTime =
    type === 'before'
      ? dateTime - timeSlotIntervalInMilliseconds
      : dateTime + timeSlotIntervalInMilliseconds

  while (isSameDay(date, iteratorDateTime)) {
    result.push(
      getNonWorkingHoursTimeSlot({
        startDate: new Date(iteratorDateTime),
      }),
    )

    iteratorDateTime =
      type === 'before'
        ? iteratorDateTime - timeSlotIntervalInMilliseconds
        : iteratorDateTime + timeSlotIntervalInMilliseconds
  }

  return result
}

const addNonWorkingHoursTimeSlotsBetweenTimeSlots = (
  timeSlots: TimeSlot[],
  timeSlotIntervalInMilliseconds: number,
) => {
  const result: TimeSlot[] = []

  for (let i = 0; i < timeSlots.length; i++) {
    result.push(timeSlots[i])

    if (i + 1 >= timeSlots.length) {
      break
    }

    const currentStartDate = timeSlots[i].startDate
    const nextStartDate = timeSlots[i + 1].startDate

    if (!nextStartDate || !currentStartDate) {
      break
    }

    const diffBetweenTimeSlotsInMilliseconds = nextStartDate.getTime() - currentStartDate.getTime()

    const needToAddNonWorkingHoursTimeSlots =
      diffBetweenTimeSlotsInMilliseconds > timeSlotIntervalInMilliseconds

    if (needToAddNonWorkingHoursTimeSlots) {
      const numberOfNonWorkingHoursTimeSlots = Math.floor(
        diffBetweenTimeSlotsInMilliseconds / timeSlotIntervalInMilliseconds,
      )

      for (let j = 1; j < numberOfNonWorkingHoursTimeSlots + 1; j++) {
        const newTimeSlotStartDate = addMilliseconds(
          new Date(currentStartDate),
          timeSlotIntervalInMilliseconds * j,
        )

        if (newTimeSlotStartDate.getTime() !== nextStartDate.getTime()) {
          result.push(
            getNonWorkingHoursTimeSlot({
              startDate: newTimeSlotStartDate,
            }),
          )
        }
      }
    }
  }

  return result
}

const getNonWorkingHoursTimeSlot = (timeSlot: TimeSlot): TimeSlot => ({
  ...timeSlot,
  status: Status.NON_WORKING_HOURS,
})
