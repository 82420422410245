import {Status, TimeSlot} from '@wix/ambassador-table-reservations-v1-time-slot/types'
import eachMinuteOfInterval from 'date-fns/eachMinuteOfInterval'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import addMinutes from 'date-fns/addMinutes'

import {timeSlotsService} from '../../services/timeSlotsService'

import {EDITOR_MOCK_RESERVATION_LOCATION_ID} from './getReservationLocationsMock'

export const getDayTimeSlotsMock = async (
  flowApi: ControllerFlowAPI,
  params: {
    reservationLocationId: string
    partySize: number
    date: Date
    timeSlotInterval?: number | null
    timeZone?: string | null
  },
): Promise<TimeSlot[]> => {
  if (!params.date) {
    return []
  }

  let dayTimeSlotsFromApi: TimeSlot[] = []

  if (params.reservationLocationId !== EDITOR_MOCK_RESERVATION_LOCATION_ID) {
    try {
      dayTimeSlotsFromApi = await timeSlotsService.getDayTimeSlots(flowApi, params)
    } catch (e) {}
  }

  if (dayTimeSlotsFromApi.length) {
    return dayTimeSlotsFromApi
  } else {
    const selectedTime = params.date

    const intervals = eachMinuteOfInterval(
      {
        start: addMinutes(selectedTime, -7 * 15),
        end: addMinutes(selectedTime, 7 * 15),
      },
      {step: 15},
    )

    return generateTimeSlots(intervals.slice(0, 15))
  }
}

const generateTimeSlot = (startDate: Date, status: Status): TimeSlot => ({
  startDate,
  duration: 30,
  status,
  tableCombinations: [],
})

export const generateTimeSlots = (dates: Date[]): TimeSlot[] =>
  Array.from({length: dates.length}).map((_, index) =>
    generateTimeSlot(dates[index], Status.AVAILABLE),
  )
