import {SiteStructure} from '@wix/native-components-infra/dist/src/types/types'
import {BreadcrumbsItem} from 'wix-ui-tpa/dist/cssVars/types/components'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

import {AppPage} from './constants'

export const getBreadcrumbsConfig = (flowAPI: ControllerFlowAPI, siteStructure: SiteStructure) => {
  const {baseUrl} = flowAPI.controllerConfig.wixCodeApi.location
  const {pages: sitePages} = siteStructure
  const {t} = flowAPI.translations

  const homePage = {
    name: t('uou-reservations.reservations.breadcrumbs-homepage-label'),
    url: '/',
  }
  const currentPageId = flowAPI.controllerConfig.wixCodeApi.site.currentPage?.id
  const currentPage = sitePages.find((page) => page.id === currentPageId)!

  const homePageCrumb = {
    id: '1',
    value: homePage.name,
    href: `${baseUrl}${homePage.url}`,
  }

  // @ts-expect-error
  if (currentPage?.tpaPageId === AppPage.Reservations) {
    return [
      homePageCrumb,
      {
        id: '2',
        value: currentPage.name,
        href: `${baseUrl}${currentPage.url}`,
      },
    ]
  }

  // @ts-expect-error
  const reservationsPage = sitePages.find((page) => page.tpaPageId === AppPage.Reservations) || {
    name: 'Reservations',
    url: '',
  }

  const breadcrumbs = [
    homePageCrumb,
    {
      id: '2',
      value: reservationsPage.name,
      href: `${baseUrl}${reservationsPage.url}`,
    },
    {
      id: '3',
      value: currentPage?.name,
      href: `${baseUrl}${currentPage?.url}`,
    },
  ]

  return breadcrumbs
}

export const generateBreadcrumbsSD = (breadcrumbs: BreadcrumbsItem[]) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((breadcrumb, index) => ({
      '@type': 'ListItem',
      name: breadcrumb.value,
      item: breadcrumb.href,
    })),
  }
}
